html,
body,
#root {
  width: 100%;
  height: 100%;
}

.slick-slide {
  img,
  div {
    outline: none !important;
  }
}

html {
  background-color: #e9f0fe;
}

@font-face {
  font-family: "avenir";
  font-display: swap;
  src: url("../src/fonts/avenir-m.otf"), url("../src/fonts/avenir-m.ttf");
}

@font-face {
  font-family: "avenir-s";
  font-display: swap;
  src: url("../src/fonts/avenir-s.otf"), url("../src/fonts/avenir-s.ttf");
}

@font-face {
  font-family: "wicked";
  font-display: swap;
  src: url("../src/fonts/wicked-hearts.otf");
}

@font-face {
  font-family: "leitura";
  font-display: swap;
  src: url("../src/fonts/leitura.otf");
}

@font-face {
  font-family: "leitura-b";
  font-display: swap;
  src: url("../src/fonts/leitura-b.otf");
}

.wicked {
  font-family: "wicked";
  line-height: 1.5;
}

.leitura {
  font-family: "leitura";
}

.leitura-b {
  font-family: "leitura-b";
}

.avenir {
  font-family: "avenir";
}

.avenir-s {
  font-family: "avenir-s";
}

.moonzo-instagram-wrapper {
  border-radius: 50% 50%;
  height: 40px;
  width: 40px;
  background: #fff;
}

.compatibility-form-select {
  padding: 1.5rem 3rem;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1;
  animation-duration: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.mb-5-responsive {
  margin-bottom: 3rem;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.transparent-bg {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}

.cookie-bar {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  bottom: 0;
  margin: 0 auto;
  font-size: 15px;
  z-index: 999999;
  width: 100%;
  box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.2);
}

.cookie-bar-hide {
  transform: translate(0, 100px);
  transition: transform 330ms ease-in-out;
}

.btn.moonzo-btn.cookie-bar-btn {
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  border-radius: 3px;
  font-size: 11px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  border: 0 !important;
}

.ReactModal__Body--open .moonzo-carousel-indicators {
  z-index: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open,
.ReactModal__Content.ReactModal__Content--after-open {
  z-index: 1000;
}

.ReactModal__Body--open i.scroll-down {
  z-index: 1 !important;
}

#compatibility-result-share {
  a{
    color: #000 !important;
    text-decoration: none !important;
    background-color: transparent;
  }
}

.moonzo-btn-primary {
  background-color: #ffb87c;
  background-image: -webkit-linear-gradient(to bottom right, #ffcc6a, #ffb87c);
  background-image: -moz-linear-gradient(to bottom right, #ffcc6a, #ffb87c);
  background-image: -o-linear-gradient(to bottom right, #ffcc6a, #ffb87c);
  background-image: linear-gradient(to bottom right, #ffcc6a, #ffb87c);
  font-weight: 800;
  min-width: 250px;
  &:focus,
  &:active,
  &:hover,
  &:visited {
    background-color: #ffb87c;
    background-image: -webkit-linear-gradient(
      to bottom right,
      #ffcc6a,
      #ffb87c
    );
    background-image: -moz-linear-gradient(to bottom right, #ffcc6a, #ffb87c);
    background-image: -o-linear-gradient(to bottom right, #ffcc6a, #ffb87c);
    background-image: linear-gradient(to bottom right, #ffcc6a, #ffb87c);
    font-weight: 800;
    min-width: 250px;
  }
}

.moonzo-custom-check.custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #41c5a4;
  background-color: #41c5a4;
}

.moonzo-btn-secondary {
  background-color: #333654;
  font-weight: 800;
  min-width: 250px;
  color: #ffffff;

  &:focus,
  &:active,
  &:hover,
  &:visited {
    background-color: #333654;
    font-weight: 800;
    min-width: 250px;
    color: #ffffff;
  }
}

.moonzo-modal-compatibility {
  background-color: #2e324f;
  background-image: url("../src/images/BACKCOMPATIBILITY.jpg");
  background-size: cover;
  color: #ffffff;
}

.moonzo-modal-body.moonzo-modal-compatibility {
  background-color: #2e324f;
  background-image: url("../src/images/BACKCOMPATIBILITY.jpg");
  background-size: cover;
  color: #ffffff;

  &.moonzo-bg-showmore {
    background-color: #2e324f;
    background-image: url("../src/images/BACKCOMPATIBILITYSHOWMORE.jpg");
    background-size: cover;
    color: #ffffff;
  }
}

#section-1 {
  padding-bottom: 100px;
}

.moonzo-form-title {
  font-family: "leitura";
  font-weight: 600;
}

.moonzo-form-label {
  font-family: "avenir-s";
}

.moonzo-form-padding {
  padding-left: 3rem;
  padding-right: 3rem;
}

.moonzo-hr {
  display: block;
  margin: 1.5rem 15px 2rem 15px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}

.ReactModal__Body--open {
  .zi-1,
  .zi-2 {
    z-index: 0;
  }
}

.safari-title-lh {
  line-height: inherit;
}

select {
  background: url("../src/images/DOWNBLACK.png") no-repeat right #ddd;
  -webkit-appearance: none;
  background-size: 15px;
  background-position-x: calc(100% - 15px);
}

.moonzo-carousel-inner {
  border-radius: 30px;
}

.moonzo-carousel-indicators {
  top: 40px;
  left: -20px;
  right: auto;
  z-index: 1;

  li.moonzo-carousel-indicator {
    &.active {
      opacity: 1;
      width: 20px;
      border-radius: 10px;
      background: white;
    }

    margin-right: 5px;
    width: 8px;
    height: 8px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 50% 50%;
    top: 20px;
  }
}

.zi-1 {
  z-index: 1;
}

.zi-2 {
  z-index: 2;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.moonzo-btn-compatibility {
  display: flex;
  align-items: flex-end;
  margin-bottom: 17px;
  justify-content: left;
}

.s3-title.wicked {
  line-height: inherit;
}

.color-red {
  color: red;
}

.footer-container {
  padding: 3rem;
}

.color-white {
  color: #ffffff;
}

.required:after {
  content: " *";
  color: red;
}

footer {
  background: #ffffff;
}

.vex.vex-theme-default .vex-content {
  border-radius: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-bg {
  background-size: cover;
  overflow: hidden;
}

.s1-bg-webp {
  background-color: #2e324f;
  background-image: url("../src/images/planetBackgroundPurple.png");
}

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;

  &.vertical-left {
    justify-content: left;
  }

  &.vertical-right {
    justify-content: right;
  }
}

.vertical-align-text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.section-2 {
  height: 100%;
  background: #e9f0fe;
  margin-bottom: 400px;
  height: 800px;
}

.section-3 {
  padding: 9rem 0 5rem;
  background-size: cover;
  position: relative;
}

.planetHomePage {
  position: absolute;
  top: -165px;
  left: 30%;
}

.s3-bg-mobile-webp {
  background-color: #ff896d;
  background-image: url("../src/images/BACK3.webp");
}

.s3-bg-mobile-jpg {
  background-color: #ff896d;
  background-image: url("../src/images/BACK3.jpg");
}

.section-3 .s3-title {
  margin-bottom: 0;
}

.card-grid {
  top: -190px;
  margin: 0;
  margin-bottom: -100px;
}

.img-contain {
  object-fit: contain;
}

.card-img-top-wrapper {
  height: 250px;
}

.moonzo-btn {
  text-transform: uppercase;
  border-radius: 17px;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
  font-family: "avenir";
  cursor: pointer;
  line-height: 0;
  font-weight: 600;
}

.pt-3px {
  padding-top: 3px;
}

a.moonzo-a {
  border: 1px solid white;
  border-radius: 40px;
  padding: 5px 20px;
  text-decoration: none;
  color: white;

  &.footer-moonzo-a {
    border: 0;
    padding: 0;
    color: #000;
    font-size: 16px;
  }
}

.btn-dark {
  background-color: #000 !important;

  &:focus,
  &:active,
  &:hover,
  &:visited,
  &:focus-visible,
  &:focus-within {
    background-color: #000 !important;
  }
}

a.moonzo-a.moonzo-a-black {
  border: 1px solid black;
  color: black;
}

.instagram-logo {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  height: 35px;
  width: 35px;
}

.moonzo-card {
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 40px;
  border: 0;
  box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.3);
}

.moonzo-card-title {
  font-size: 25px;
  font-weight: 600;
}

.moonzo-modal-body {
  background-color: #e9f0fe;
}

.footer-copyright {
  font-size: 13px;
  color: #333333;
  opacity: 0.5;
}

input.moonzo-input,
select.moonzo-input {
  border-radius: 25px;
  height: 45px;
}

.border-radius-img {
  border-radius: 15px;
}

.s2-img-container {
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
}

.moonzo-footer {
  padding: 3rem;
}

.fs-s1,
.fs-s2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.fs-s3 {
  margin-left: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.footer-insta-bg {
  background-color: #ee7671;
}

.s2-text-resp {
  margin-bottom: 1.5rem;
}

.mt-5-responsive {
  margin-top: 3rem;
}

.py-5-responsive {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.my-5-responsive {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-5-responsive-btn {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

@media (max-width: 576px) {
  .planetHomePage {
    display: none;
  }
  #section-1 {
    padding-bottom: 0;
  }
  .my-5-responsive-btn,
  .my-5-responsive {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .py-5-responsive {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .mt-5-responsive {
    margin-top: 1.5rem;
  }

  .mb-5-responsive {
    margin-bottom: 1.5rem;
  }

  .compatibility-form-select {
    padding: 1.5rem;
  }

  .moonzo-modal-compatibility {
    background-color: #2e324f;
    background-image: url("../src/images/BACKCOMPATIBILITYMOBILE.jpg");
    background-size: cover;
    color: #ffffff;
  }

  .moonzo-modal-body.moonzo-modal-compatibility {
    background-color: #2e324f;
    background-image: url("../src/images/BACKCOMPATIBILITYMOBILE.jpg");
    background-size: cover;
    color: #ffffff;

    &.moonzo-bg-showmore {
      background-color: #2e324f;
      background-image: url("../src/images/BACKCOMPATIBILITYMOBILE.jpg");
      background-size: cover;
      color: #ffffff;
    }
  }

  .moonzo-form-title {
    font-size: 1.2rem;
  }

  .moonzo-form-label {
    font-size: 1.1rem;
  }

  .moonzo-carousel-indicators {
    top: 20px;
    left: 0;
    right: 0;
  }

  .s1-bg-webp {
    background-color: #2e324f;
    background-image: url("../src/images/planetBackgroundPurpleMobile.png");
    background-position: right;
  }

  .mt-0.s1-sm-title-resp,
  .s1-sm-title-resp {
    margin-top: 0 !important;
  }

  .responsive-100.h-100 {
    height: auto !important;
    padding-bottom: 2rem;
    padding-top: 3rem;
    text-align: center;
  }

  .wicked.result-title-resp,
  .result-title-resp {
    font-size: 1.5rem;
  }

  a.moonzo-a {
    border: 0;
  }

  .display-4 {
    font-size: 10vw;
  }

  .vertical-left.header-wrapper {
    justify-content: center;
  }

  .moonzo-btn {
    width: 100%;
  }

  .s2-img-container {
    padding-right: 0;
  }

  .card-grid {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: -100px;
  }

  .moonzo-card {
    margin-bottom: 2rem;
  }

  .navbar-row {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
  }

  .s3-compatibility-text {
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
  }

  .moonzo-footer {
    padding: 35px 10px;
  }

  .fs-s {
    font-size: 16px;
    margin: 0 auto;
  }

  .vertical-align.vertical-right.cgu-wrapper,
  .cgu-wrapper {
    justify-content: center;
    margin-top: 1.5rem;
  }

  .moonzo-footer-insta {
    margin: 0 auto;
  }

  .section-3 {
    background-size: cover;
  }

  .s3-bg-mobile-webp {
    background-color: #ff896d;
    background-image: url("../src/images/BACK3MOBILE.webp");
  }

  .s3-bg-mobile-jpg {
    background-color: #ff896d;
    background-image: url("../src/images/BACK3MOBILE.jpg");
  }

  .peoples-img {
    position: absolute;
    height: 100%;
    opacity: 0.3;
    left: 0;
    overflow-x: hidden;
  }

  .moonzo-logo-resp {
    height: 30px;
  }

  .moonzo-close-resp {
    height: 15px;
  }

  .col-lg-5.col-md-5.vertical-align.s3-access-sm {
    border-radius: 0 0 25px 25px;
  }
}

@media (min-width: 768px) {
  .section-2 {
    margin-bottom: 150px;
  }

  .sm-show {
    display: none;
  }

  .sm-hidden {
    display: visible;
  }

  .s3-access-sm {
    background: transparent;
    border-radius: 0;
    padding: 0;
  }

  .s3-access-md {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 25px;
    padding: 3rem;
  }
}

@media (max-width: 767px) {
  .moonzo-form-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mt-0.s1-sm-title-resp,
  .s1-sm-title-resp {
    margin-top: 3rem;
  }

  .responsive-100.h-100 {
    height: auto !important;
    padding-bottom: 2rem;
    text-align: center;
  }

  .s3-access-md {
    background: transparent;
    border-radius: 0;
    padding: 0;
  }

  .s3-access-sm {
    background: rgba(255, 255, 255, 0.2);
    margin: 0;
    border-radius: 25px;
    padding: 2rem;
  }

  .sm-no-padding {
    padding: 0;
  }

  .peoples-img {
    position: absolute;
    height: 100%;
    opacity: 0.3;
    left: 0;
    overflow-x: hidden;
  }

  .vertical-align.moonzo-footer-col,
  .moonzo-footer-col {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .vertical-align.moonzo-footer-col,
  .moonzo-footer-col .footer-copyright {
    margin: 1rem auto;
  }

  .footer-moonzo-left,
  .mx-3.footer-moonzo-left {
    margin-left: auto !important;
  }

  .footer-moonzo-right {
    margin-right: auto;
  }

  .footer-container {
    padding: 1.5rem 0;
  }

  .sm-show {
    display: visible;
  }

  .sm-hidden {
    display: none;
  }

  .s2-title-resp {
    margin-top: 3rem;
  }

  .s2-text-resp {
    margin-bottom: 0;
  }

  .section-2 {
    text-align: center;
  }

  .moonzo-btn {
    width: 100%;
  }

  .section-2 {
    margin-bottom: 91vw;
  }

  .slick-slider {
    padding: 0 20px;
    .slick-arrow {
      &.slick-prev {
        left: -10px;
      }
      &.slick-next {
        right: -10px;
      }
    }
  }
}

@media (min-width: 576px) {
  .moonzo-card {
    margin-bottom: 3rem;
  }

  .xs-show {
    display: none;
  }

  .section-2 {
    margin-bottom: 500px;
  }
}

@media (min-width: 737px) {
  .section-2 {
    margin-bottom: 500px;
  }
}

@media (min-width: 768px) {
  .section-2 {
    margin-bottom: 50px;
    padding-top: 4rem;
  }
}

@media (min-width: 1200px) {
  .section-2 {
    margin-bottom: 100px;
    padding-top: 3rem;
  }
}

@media (max-width: 500px) {
  .section-2 {
    margin-bottom: 91vw;
  }
}

@media (max-width: 490px) {
  .section-2 {
    margin-bottom: 91vw;
  }
}

@media (max-width: 414px) {
  .section-2 {
    margin-bottom: 91vw;
  }

  .wicked {
    font-size: 2rem;
  }
}

@media (max-width: 370px) {
  .section-2 {
    margin-bottom: 94vw;
  }

  .wicked {
    font-size: 1.5rem;
  }
}

@media (max-width: 340px) {
  .section-2 {
    margin-bottom: 101vw;
  }

  .s3-access-sm {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 334px) and (max-width: 340px) {
  .section-2 {
    margin-bottom: 94vw;
  }
}

@media (max-width: 320px) {
  .section-2 {
    margin-bottom: 101vw;
  }
}

@media (max-width: 301px) {
  .section-2 {
    margin-bottom: 113vw;
  }
}

@media (min-width: 736px) and (max-width: 992px) {
  .moonzo-card-title {
    font-size: 20px;
  }

  .moonzo-carousel-indicators {
    top: 20px;
  }

  .moonzo-form-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .s2-img-container {
    padding: 3rem 0 3rem 0;
  }
}

@media (max-height: 625px) {
  .responsive-100.h-100 {
    height: auto !important;
  }
}

// New page designs
.formFirstPage {
  display: flex;
  flex-direction: column;
  #moonzo-modal-compatibility {
    order: 1;
    background-color: #2e324f;
    background-image: url('../src/images/formPageBackground.png');
    background-size: cover;
  }
  .multiSectionWrapper {
    background-color: #ff896d;
    background-image: url('../src/images/BACK3.webp');
    background-size: cover;
  }
  .multiSectionWrapper {
    order: 2;
  }
  #section-1 {
    padding-top: 50px;
    padding-bottom: 0 !important;
    .navbar-container {
      .vertical-left {
        h1 {
          font-size: 58px;
        }
        span {
          font-size: 24px;
        }
      }
    }
  }
  #section-1, #section-3 {
    background-image: none;
    background-color: transparent;
  }
  #section-3 {
    .btn-app-apple {
      display: none;
    }
  }
}

.scroll-down {
  display: none !important;
}

.moonzo-modal-compatibility {
  .col {
    h1 {
      &:first-child {
        font-size: 60px;
      }
      &:nth-child(2) {
        font-size: 53px;
      }
    }
    h3 {
      font-size: 36px;
    }
  }
}

.moonzoShowMoreCelebs {
  position: absolute;
  padding: 30px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  background-color: #2e324f;
  background-image: url("../src/images/BACKCOMPATIBILITY.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) {
  .scroll-down {
    display: inline !important;
  }

  .formFirstPage{
    #section-1 {
      padding-top: 0;
      .navbar-container {
        padding-top: 20px;
        .vertical-left {
          h1 {
            font-size: 30px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
    #moonzo-modal-compatibility {
      background-image: url('../src/images/formPageBackgroundMobile.png');
      background-position-y: -180px;
    }
  }
  .moonzo-modal-compatibility {
    .col {
      h1 {
        &:first-child {
          font-size: 33px;
        }
        &:nth-child(2) {
          font-size: 34px;
        }
      }
      h3 {
        font-size: 18px;
      }
    }
  }
}

// Apple Store Button
.btn-app-apple {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
  background-color: #000;
  border: 1px solid #a6a6a6;
  border-radius: 10px;
  color: #fff !important;
  text-decoration: none !important;
  padding: 5px 20px 7px;
  h2 {
    margin-bottom: 0;
    line-height: 1;
    letter-spacing: 1px;
  }
}

// Celebrity Carousal
.celebrity-bg-selected {
  background: linear-gradient(0, #ffb87c 77%, transparent 60%);
  padding: 5px;
  padding-top: 8px;
}

.celebrity-img-pink {
  background: linear-gradient(0, #eed7ef 77%, transparent 60%);
}

.celebrity-img-blue {
  background: linear-gradient(0, #b5bfd3 77%, transparent 60%);
}

.celebrity-img-green {
  background: linear-gradient(0, #9ed6c8 77%, transparent 60%);
}

.celebrity-img-orange {
  background: linear-gradient(0, #ffb97e 77%, transparent 60%);
}

.celebrity-img-selected {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);

  &.greyscale {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}

.greyscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.celebrity-img {
  &:focus,
  &:focus-within {
    outline: unset !important;
  }
}

.celebrity-padding {
  padding: 0 1.5rem 15px 1.5rem;
}

.celebrity-name-padding {
  margin-top: 0;
  margin-bottom: 1rem;
}

@media (max-width: 576px) {
  .celebrity-padding {
    padding: 0;
  }

  .col-padding {
    padding-left: 5px;
    padding-right: 5px;
  }

  .celebrity-name-padding {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

// Autocomplete
.AutoCompleteText {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);

  input {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.73);
    box-sizing: border-box;
    outline: none;
  }

  ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border: 1px solid #ced4da;
    border-top: none;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    position: absolute;
    width: 100%;
    z-index: 10;
  }

  .has-suggestion {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ul::before {
    content: "";
  }

  li {
    padding: 10px 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      background-color: rgba(128, 128, 128, 0.2);
    }
  }
}

// Compatibility Result
.moonzo-modal-body.green {
  background-color: #91d1c1;
  background-image: url("../src/images/BACKRESULT.jpg");
  background-size: cover;
}

.green-bg {
  background-color: #91d1c1;
  background-image: url("../src/images/BACKRESULT.jpg");
}

.moonzo-result-page {
  padding-bottom: 8rem;
}

.moonzo-share-insta {
  bottom: 2.5rem;
  left: 0;
  right: 0;
  width: 280px;
}

.moonzo-result-container {
  max-width: 800px;
}

.moonzo-result-symbol-sign {
  border-radius: 50% 50%;
  height: 45px;
  width: 45px;
  background: #000000;
}

.moonzo-result-symbol {
  background: white;
  padding: 2px 10px 2px 2px;
  border-radius: 25px;
  text-transform: capitalize;
  display: inline-flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.moonzo-result-graph-wrapper {
  background: #ffffff;
  border-radius: 35px;
  padding: 1rem 1rem 2rem 1rem;
  max-width: 600px;
  margin: 0 auto;
}

.result-resp-row-wrapper {
  margin-top: 1.5rem;
}

.font-600 {
  font-weight: 600;
}

.result-resp-row1-wrapper {
  margin-bottom: 1rem;
}

.result-resp-row2-wrapper {
  margin-bottom: 2rem;
}

.col-vertical-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .moonzo-x-space {
    display: block;
  }

  .moonzo-result-symbol {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 2px 25px 2px 2px;
    line-height: normal;
    margin: 0;
  }

  .moonzo-result-symbol-sign {
    display: flex;
  }

  .moonzo-result-container {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .col-vertical-mobile {
    display: initial;
  }

  .moonzo-share-insta {
    width: 90%;
  }

  .moonzo-modal-body.green {
    background-color: #91d1c1;
    background-image: url("../src/images/BACKRESULTMOBILE.jpg");
  }

  .result-resp-row1-wrapper {
    margin-bottom: 0.5rem;
  }

  .result-resp-row-wrapper {
    margin-top: 0.5rem;
  }

  .wicked.result-title-resp {
    font-size: 20px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .moonzo-result-graph-wrapper {
    padding: 0 0 1rem 0;
    margin: 0 auto;
    width: 65vw;
  }

  .result-subtitle-resp {
    font-size: 16px;
    margin-bottom: 0;
  }

  .moonzo-result-symbol-sign {
    height: 32px;
    width: 32px;
  }

  .moonzo-x-space {
    margin-bottom: 8px;
  }

  .moonzo-logo-resp {
    height: 30px;
  }

  .moonzo-close-resp {
    height: 15px;
  }
}

@media (max-width: 375px) {
  .moonzo-result-graph-wrapper {
    width: 90%;
  }
}

@media (max-width: 320px) {
  .moonzo-result-graph-wrapper {
    width: 100%;
  }
}

// Early
.color-red {
  color: red;
}

.required:after {
  content: " *";
  color: red;
}

.early-button-wrapper {
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .early-button-wrapper {
    padding-left: 0;
  }
}

@media (max-height: 815px) {
  .h-100.h-100-early-responsive {
    height: auto !important;
  }

  .early-button-wrapper {
    margin-top: 0;
  }
}

// Footer
.pt-3px {
  padding-top: 3px;
}

.footer-moonzo-a {
  margin: 0 10px;
}

.moonzo-footer-col {
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .footer-moonzo-a {
    margin: 0.5rem 0;
    display: block;
    text-align: right;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section-3 {
    padding: 25px 0;
  }
  .btn-app-apple {
    width: 260px;
    img {
      width: 30px;
    }
    h2 {
      font-size: 30px;
    }
  }

  .footer-moonzo-a {
    margin: 0.5rem 0;
    display: block;
    text-align: center;
    width: 100%;
  }
}

@media (max-width : 568px) {
  .btn-app-apple {
    width: 240px;
  }
}
